import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import { CommonBasketCookieBase } from '@tgg/common-types';
import {
    createCookieApi,
    AVAILABLE_COOKIES,
    userIdentifierCookieApi,
} from '@tgg/util';

import { LocalConfig } from 'apps/commerce/next.config.types';

import { ShoppingBasketCookie } from './cookies.types';

const {
    publicRuntimeConfig: { APP_ENVIRONMENT },
}: LocalConfig = getConfig();

export const createBasketCookieApi = () =>
    createCookieApi<ShoppingBasketCookie>(
        'commerce',
        AVAILABLE_COOKIES.DEFAULT_BASKET,
        {},
        {
            expires: 14, // 7 days - When cookie is expired, the browser should take care of cookie cleanup.
            sameSite: 'lax' as const,
        },
        APP_ENVIRONMENT,
    );

export const basketCookieApi = createBasketCookieApi();

type SetupBasketCookie = {
    initialCookieData?: ShoppingBasketCookie;
    memberId?: string;
    force: boolean;
};

/**
 * Sets up the 'shoppingBasket' cookie clientside.
 * Should be called once in every page in the join journey.
 */
export const setupShoppingBasketCookie = ({
    initialCookieData,
    memberId,
    force,
}: SetupBasketCookie) => {
    // should not do anything when code of page is executed serverside.
    if (typeof document === 'undefined') {
        return;
    }
    const shoppingBasketCookie = getShoppingBasketCookie();
    const genericUserId = userIdentifierCookieApi.browser.getCookie();
    const userId = memberId ?? genericUserId ?? uuidv4();

    if (!shoppingBasketCookie || force) {
        const basketInitData: CommonBasketCookieBase = {
            ...initialCookieData,
            userId,
            paymentId: uuidv4(),
        };
        updateShoppingBasketCookie(basketInitData);
    }
};

/**
 * Updates the value of 'shoppingBasket' cookie.
 * Should only be called from clientside CTAs.
 * It has the same effect everytime it's called, i.e. it will not create a new cookie and will just update the correct one.
 */
export const updateShoppingBasketCookie = basketCookieApi.browser.setCookie;

/**
 * Gets the value of 'shoppingBasket' cookie.
 * Able to handle only clientside calls.
 */
export const getShoppingBasketCookie = basketCookieApi.browser.getCookie;

/**
 * Inserts a new value into the existing cookie,
 * overwrites existing one if present
 */
export const upsertShoppingBasketCookie = basketCookieApi.browser.updateCookie;

/**
 * Parses cookies received by the server in the HTTP Cookie header in the Request.
 * Then parses the 'shoppingBasket' cookie to JSON.
 * Can only be called from Serverside.
 */
export const getShoppingBasketCookieServerside =
    basketCookieApi.ssr.getCookieSsr;

/**
 * Serializes cookie information on the server side
 */
export const updateShoppingBasketCookieServerSide =
    basketCookieApi.ssr.setCookieSsr;

/**
 * Delete the shopping basket cookie
 */
export const deleteShoppingBasketCookie = basketCookieApi.browser.deleteCookie;
