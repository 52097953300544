import { PlaceRating } from '@tgg/common-types';
import {
    TggLogger,
    TggTraceCorrelationParameters,
    createApiService,
} from '@tgg/util';

import { PlaceRatingResponse } from './googlePlaces.types';

const okStatus = 'OK';

/**
 * getPlaceRating is used to get rating details of a Google Place.
 * Warning! Using Google Place API leads to high costs. Therefore the service is not currently used.
 *
 * @param {string} placeId - an Id of a Place on Google Maps
 * @param {string} apiKey - apiKey needed for Google Maps API requests
 * @param {ClientLogger | ServerLogger} logger - a logger instance
 * @param {string} loggedApplication - an application identifier for logging
 * @returns {Promise} returns rating details.
 */

export const getPlaceRating = async (
    placeId: string,
    apiKey: string,
    logger: TggLogger,
    loggedParameters: TggTraceCorrelationParameters,
): Promise<PlaceRating | null> => {
    const getPlaceRatingService = createApiService<'get', PlaceRatingResponse>(
        'get',
        {
            path: `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}&fields=rating,user_ratings_total,url`,
            apiVersion: 'v1.0',
        },
    );

    try {
        const { data } = await getPlaceRatingService({
            apiConfig: {
                subscriptionKey: '',
            },
            accessToken: '',
        });

        const { status, result, error_message: errorMessage } = data.data;

        if (status !== okStatus) {
            logger.error(
                `getPlaceRating: ${status} - ${errorMessage} PlaceId: ${placeId}`,
                loggedParameters,
            );
            return null;
        }

        if (!result || !result.rating || !result.user_ratings_total) {
            logger.warn(
                `getPlaceRating: rating result is empty. PlaceId: ${placeId}`,
                loggedParameters,
            );
            return null;
        }

        // const placeReviewDirectUrl = `https://search.google.com/local/reviews?placeid=${placeId}`;

        const ratingResult: PlaceRating = {
            rating: result.rating,
            userRatingsTotal: result.user_ratings_total,
            url: result.url,
        };

        return ratingResult;
    } catch {
        logger.error(
            'Unknown error during Google Place API request',
            loggedParameters,
        );
        return null;
    }
};
