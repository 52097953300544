import { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';

import { GymReference } from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import { CmsContentSchema } from '@tgg/micro-services/cms-client';
import { relativeUrlWithBasePath } from '@tgg/util';

export default function useMapsGymClick() {
    const router = useRouter();

    const onGymClick = async ({ branchId }: { branchId: string }) => {
        const response: AxiosResponse<CmsContentSchema[]> =
            await axiosInstance.get(
                relativeUrlWithBasePath(`/api/cms/gyms/${branchId}/`),
            );

        // @ts-ignore
        const [gym] = response.data as GymReference[];

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        await router.push(gym?.gymPageURL || '/find-a-gym/');
    };

    return onGymClick;
}
