import { BlogPostProperties, StoryBoxSize } from '@tgg/common-types';

import { StoryBoxProperties } from 'libs/ui/src/lib/components/StoryBox/StoryBox.types';

export const mapPosts = (
    posts: BlogPostProperties[],
    categoryPagesMap: Record<string, string>,
    overrideSize?: StoryBoxSize | null,
): StoryBoxProperties[] => {
    return posts.map(({ details, _meta: meta }, index) => {
        const tagsMap = {} as Record<string, any>;

        tagsMap[details.mainSubcategory.name] = `/${
            categoryPagesMap[details.mainSubcategory.parentCategory.name]
        }?filter=${encodeURIComponent(details.mainSubcategory.name)}`;

        if (details.subcategories) {
            details.subcategories.forEach(({ name, parentCategory }) => {
                tagsMap[name] = `/${
                    categoryPagesMap[parentCategory.name]
                }?filter=${encodeURIComponent(name)}`;
            });
        }

        const tags = Object.entries(tagsMap)
            .map(([text, href]) => ({ text, href }))
            .sort((a, b) => a.text.localeCompare(b.text));

        return {
            title: details.title,
            image: details.image,
            paragraph: details.description,
            button: {
                href: `/${meta.deliveryKey}`,
                text: details.buttonText || 'Read article',
            },
            size: overrideSize || (index < 2 ? 'large' : 'small'),
            variant: 'white',
            tags: tags.slice(0, 4),
        };
    });
};
