import { AxiosResponse } from 'axios';

import { MappedGym } from '@tgg/common-types';
import { ApiResponse } from '@tgg/services';

import { formatAddress } from '../../core/dataManipulation';

import { FilteredGymObject } from './FindAGym.types';

export const gymsListMapper = (
    gymsFromAPI: AxiosResponse<ApiResponse>,
    gymsFromCMS: MappedGym[],
) => {
    const gymsInformationByBranchId: {
        [branchId: string]: FilteredGymObject;
        // eslint-disable-next-line unicorn/prefer-object-from-entries
    } = gymsFromAPI.data.Data.reduce((accumulator, currentGym) => {
        const { BranchId, ...gymInfo } = currentGym;
        const { Name, Address, Longitude, Latitude } = gymInfo;

        if (!BranchId || !Name || !Address || !Latitude || !Longitude) {
            return accumulator;
        }

        return { ...accumulator, [BranchId.toLowerCase()]: gymInfo };
    }, {});

    return Object.entries(gymsInformationByBranchId).map(([branchId, gym]) => {
        const { Latitude, Longitude, Name, LowestPrice, JoiningFee, Address } =
            gym;

        const gymAddress = formatAddress(
            Address.address1,
            Address.address2,
            Address.city,
            Address.postcode,
        );
        const gymFromCMSWithUrl = gymsFromCMS.find(
            gymFromCMS => gymFromCMS.branchId === branchId,
        );

        return {
            position: {
                lat: Latitude,
                lng: Longitude,
            },
            gym: {
                branchId: branchId.toLowerCase(),
                gymName: Name,
                gymAddress,
                lowestPrice: LowestPrice,
                joiningFee: JoiningFee,
                gymPageURL: gymFromCMSWithUrl?.gymPageURL || '/find-a-gym/',
            },
        };
    });
};
